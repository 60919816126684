@charset "UTF-8"; 
/*------------------------------------------------------------------
[Master Stylesheet]
Project:	Beavis
++++ Imports ++++
++++ Reset ++++
++++ Main ++++
++++ Mediaqueries ++++
-------------------------------------------------------------------*/

/****************************/
/** Imports **/
/****************************/
@import "jquery.countdown.css";	
@import "animate.css";	
@import "component2.css";	
@import "font-awesome.min.css";	
@import "et-line-font.css";	
@import "bootstrap.min.css";	
@import "inputtext.css";	
@import "photoswipe.css";	
@import "default-skin/default-skin.css";	

$primery_color: #000;
$primery_font_color: #000;
$secondery_color:#fff;
$background_color:#fff;
$about_background:#000;
$background_img:url("../img/mainbg.jpg");
$contact_background:#121212;


$font: 'Poppins', sans-serif;
$secondery_font:  'Hind', sans-serif;;

@mixin background-opacity($color, $opacity: 0.6) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin clear() {
	overflow:hidden;
	clear:both;
	float:none;
}

/****************************/
/** Reset **/
/****************************/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, pre, a, abbr, acronym,address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

strong{
	font-weight:bold;
}
 
body {
	color: $primery_font_color;
	background:$background_color;
	font-family:$font;
	font-size: 14px;
	font-style: normal;
	line-height:1.5;
	overflow-y:auto;
}
/* Mozilla based browsers */
::-moz-selection {
       background: #f8ec2e;
       color: #000;
}

/* Works in Safari */
::selection {
       background: #f8ec2e;
       color: #000;
}
#ascrail2000 {
	z-index:999!important;
}
a:hover,a:active {
    outline: 0;
}

button:focus{
	border:none;
	outline:0;
}

ul {
    list-style: none;
}

a {
    color: $secondery_color;
    text-decoration: none;
}

a:hover {
    color: $secondery_color;
    text-decoration: none;
}

a:focus {
 	color: $secondery_color;
    text-decoration: none;
    outline: none;
}
h1,h2,h3,h4,h5,h6 {
	color:$primery_font_color;
}
h1{
	font-size:72px;
	line-height:72px;
	text-transform:capitalize;
	font-weight:600;
	letter-spacing:-2px;
	margin-bottom:15px;
}
h3{
	font-size:24px;
	line-height:35px;
	padding-bottom:32px;
	color:$secondery_color;
}
h5{
	font-size:18px;
	text-transform:uppercase;
	line-height:25px;
	margin-bottom:15px;
}
p{
	font-size:15px;
	font-family:$secondery_font;
	color:#8d8d8d;
	line-height:25px;
}
.force-UTF-8{
    content: "¡";
}
/****************************/
/** Main **/
/****************************/

.pad-zero {
	padding:0;
}
.pad-left-zero {
	padding-left:0;
}
.pad-right-zero {
	padding-left:0;
}

.sec-pad{
	padding-top:100px;
	padding-bottom:100px;
}
.sec-pad-sm{
	padding-top:50px;
	padding-bottom:50px;
}
.sec-pad-xs{
	padding-top:30px;
	padding-bottom:30px;
}
.sec-pad-top{
	padding-top:100px;
}

.sec-pad-top-sm{
	padding-top:50px;
}
.sec-pad-top-xs{
	padding-top:30px;
}
.sec-pad-bottom {
	padding-bottom:100px;
}	

.sec-pad-bottom-sm {
	padding-bottom:100px;
}

.sec-pad-bottom-xs{
	padding-bottom:30px;
}
.sec-pad-left{
	padding-left:100px;
}	
.sec-pad-right{
	padding-right:100px;
}	
.padding-top-lg-175 {
	padding-top:175px;
}
.padding-top-lg-170 {
	padding-top:170px;
}
.padding-top-sm {
	padding-top:60px;
}
.margin-btm-lg {
	margin-bottom:47px;
}
.margin-top-sm {
	margin-top:35px;
}
.margin-btm-sm {
	margin-bottom:30px;
}
.margin-top-xs-20 {
	margin-top:20px;
}
.opacity-hide {
	opacity:0;
	visibility:hidden;
}
.preloader-it {
	background: #fff;
	position: fixed;
	z-index: 110;
	height: 100%;
	width: 100%;
	overflow:hidden!important;
}
/* Effect 1: YouTube loading */
.la-anim-1 {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 10px;
	background: #000;
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
	pointer-events: none;
}

.la-anim-1::after {
	position: absolute;
	top: 0;
	right: 0;
	width: 100px;
	height: 100%;
	background: #000;
	content: '';
	opacity: 0;
	-webkit-transition: opacity 0.2s, box-shadow 2s 4s;
	transition: opacity 0.2s, box-shadow 2s 4s;
	-webkit-transform: rotate(2deg) translateY(-2px);
	transform: rotate(2deg) translateY(-2px);
}

.la-anim-1.la-animate {
	z-index: 100;
	opacity: 1;
	-webkit-transition: -webkit-transform 5s ease-in, opacity 1s 5s;
	transition: transform 5s ease-in, opacity 1s 5s;
	-webkit-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}

.la-anim-1.la-animate::after {
	opacity: 1;
}

.side-left-overlay,
.side-right-overlay {
		position:absolute;
		top:0;
		z-index:-1;
		height:100%;
		width:100%;
		background:rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
	}

@-webkit-keyframes back_ken {
	0% {
	-webkit-transform: scale(1);
	}

	50% {
	-webkit-transform: scale(1.4);
	}

	100% {
	-webkit-transform: scale(1) ;
	}
}
@-moz-keyframes back_ken {
	0% {
	-moz-transform: scale(1);
	}

	50% {
	-moz-transform: scale(1.4);
	}

	100% {
	-moz-transform: scale(1) ;
	}
}
@-o-keyframes back_ken {
	0% {
	-o-transform: scale(1);
	}

	50% {
	-o-transform: scale(1.4);
	}

	100% {
	-o-transform: scale(1) ;
	}
}
@keyframes back_ken {
	0% {
	transform: scale(1);
	}

	50% {
	transform: scale(1.4);
	}

	100% {
	transform: scale(1) ;
	}
}
		
.side-left {
	padding-top:100px;
	.leftken {
		position:absolute;
		top:0;
		z-index:-1;
		height:100%;
		width:100%;
		background:$background_img;
		background-size:cover;
		background-position:center;
		background-repeat:no-repeat;
	}
	p {
		color:$secondery_color;
	}
	.small-hr {
		margin-top:17px;
		margin-bottom:25px;
	}
	.tag-wrap {
		position:absolute;
		bottom:97px;
	}
	footer{
		float:left;
	}
	#countdown {
		color:$secondery_color;
		font-size:18px;
		margin-top:26px;
	}
	
	.content-wrap {
		height:100%;
		padding-left:100px;
	}
}
.preloader {
	background:$background_color;
}
p.tagline {
		font-size:30px;
		font-weight:600;
		line-height:45px;
		letter-spacing:-1px;
	}
.side-left{
	
}	
.side-right {
	background:$background_color;
}	
	
	.social-icons {
		float:left;
		margin-right:22px;
		li {
			display: inline-block;
			float: left;
			margin-right: 20px;
			&:last-child {
				margin-right:0;
			}
		}
		.social-icon {
			color: $primery_font_color;
			font-size:18px;
			overflow:hidden;
			text-transform:uppercase;
			line-height:25px;
			position:relative;
			display: inline-block;
			span {
				&.fa {
					line-height:25px;
				}
				display: inline-block;
				position: relative;
				@include transition(transform 0.3s ease 0s);
				&:before {
					content: attr(data-hover);
					position: absolute;
					top: 100%;
					transform: translateY(0px);
					-webkit-transform: translateY(0px);
					-ms-transform: translateY(0px);
				}
			}
			
			&:hover {
				span{
					transform: translateY(-100%);
					-webkit-transform: translateY(-100%);
					-ms-transform: translateY(-100%);
				}
			
				i.fa-facebook {
					color:#3B5998;
				}
				i.fa-twitter {
					color:#00aced;
				}
				i.fa-linkedin {
					color: #007bb6;
				}
				i.fa-behance {
					color:#1769ff;
				}
			}
		}
	}
	
.side-right {
  
    color: $primery_color;
    right: 0;
	.social-wrpper {
		bottom: 92px;
		left: 100px;
		position: absolute;
		width: 100%;
	}
	.copyright-text {
		p { 
			font-size:9px;
			letter-spacing:1px;
			text-transform:uppercase;
			color:#8d8d8d;
			float:left;
		}	
	}
	
	.twitter-sec {
		width:600px;
		float:left;
		.twiter-ico-wrap {
			border: 2px solid rgba(255,255,255,0.3);
			border-radius: 50%;
			display: block;
			float: left;
			margin-right:14px;
			height: 50px;
			text-align: center;
			width: 50px;
			i {
				padding-top:16px;
			}
		}
		#tweecool {
			min-height:125px;
			position:relative;
			font-family:$secondery_font;
			font-size:16px;
			line-height:27px;
			width:80%;
			float:left;
			ul li{
			  position: absolute;
			  opacity: 0;
			  visibility: hidden;
			  @include transition(all 1s ease-in-out);
				&.current {
					opacity: 1;
					visibility: visible;
				}
				img {
					display:none;
				}
				span {
					display:block;
					font-size:13px;
					color:#8d8d8d;
					padding-top:2px;
				}
			}
		}
		
	}

}
.form-control.notify-input-box {
	&::-webkit-input-placeholder {
	   color: $primery_font_color;
	   font-size:16px;
	   letter-spacing:1px;
	   text-transform:uppercase;
	   opacity: 1;
	}

	&:-moz-placeholder { /* Firefox 18- */
	   color: $primery_font_color;
	   font-size:16px;
	   letter-spacing:1px;
	   text-transform:uppercase;
	   opacity: 1;
	}

	&::-moz-placeholder {  /* Firefox 19+ */
	  color: $primery_font_color;
	   font-size:16px;
	   letter-spacing:1px;
	   text-transform:uppercase;
	   opacity: 1;
	}

	&:-ms-input-placeholder {  
	   color: $primery_font_color;
	   font-size:16px;
	   letter-spacing:1px;
	   text-transform:uppercase;
	   opacity: 1;
	}	
	
}
.align-center {
   @include clear;
   margin:0 auto;
}
.logo {
	display: inline-block;
}
.type-wrap {
	min-height:120px;
	padding-bottom:40px;
}

.small-hr {
    background: $primery_color none repeat scroll 0 0;
    display: block;
    height: 4px;
    width: 32px;
}

.bg-img{
    background-size:cover;
	background-position:center;
	background-repeat:no-repeat;
}
.page-left .page-inner {
	.container-fluid{
		padding-left: 100px;
		padding-right: 100px;
	}
		
	.small-hr {
		margin-top:9px;
		margin-bottom:23px;
	}
	.trans-overlay-dark-about{
		@include background-opacity($secondery_color, 0);
		position:absolute;
		top:0;
		height:100%;
		width:100%;
		z-index:1;
	}
	
}
.trans-overlay-dark{
	@include background-opacity($secondery_color, 0.4);
	position:absolute;
	top:0;
	height:100%;
	width:100%;
	z-index:1;
}
.project-gal-wrap {
	img {
		width:100%;
	}
}
.pswp__bg {
    background: rgba(0, 0, 0, 0.95) none repeat scroll 0 0;
}
.pswp__caption {
	bottom:130px;
}
.pswp__caption__center {
    color: #fff;
    font-size: 15px;
	font-family:$secondery_font;
    text-align: center;
    text-transform: capitalize;
}
.page-right .page-inner {
	.container-fluid {
		padding-left:100px;
		padding-right:100px;
	}
	.panel-body {
		padding-top:0;
	}
	.client-sec {
		.col-sm-3 {
			text-align:center;
			img {
				display:inline-block;
			}
		}	
	}
	.about-sec {
		.about-icon-wrap {
			span {
				display:block;
				&.key-fact {
					font-size:16px;
					color:#fff;
					text-transform:capitalize;
					margin-bottom: 14px;
					margin-top: 25px;
				}
			}
			.icon {
				color:#cacaca;
				font-size:48px;
			}
		}	
	}
	.team-sec {
		span {
			display:block;
		}
		.team-per {
			font-size:13px;
			color:#fff;
			letter-spacing:2px;
			text-transform:uppercase;
			margin-bottom: 3px;
			margin-top: 20px;
		}
		.team-desn{
			font-size:12px;
			color:#8d8d8d;
			text-transform:capitalize;
			margin-bottom:17px;
		}
		.team-social {
			float:left;
			li {
				margin-right:12px;
				float:left;
				a{
					i {
						color:#fff;
						font-size:12px;
						@include transition(all .3s ease);
					}
				&:hover {
					i {
						color:#8d8d8d;
					}
				}	
				}
			}
		}
	}
	.project-gal-wrap {
		margin-top: 60px;
	}
	@extend .bg-img;
	background: $about_background;
	.pswp__caption__center {
		display:none;
	}
	figure {
		figcaption {
			font-family:$secondery_font;
			font-size:12px;
			text-transform:capitalize;
			color:#8d8d8d;
			padding:20px 0 0 0;
		}
		a {
			display: block;
			overflow: hidden;
			position: relative;
		
		.hover {
			position:absolute;
			left:0;
			top:0;
			opacity:0;
			height:100%;
			width:100%;
			background:rgba(0,0,0,0.6);
			@include transition(all .4s ease-in-out);
			 span {
				display: block;
				left: 0;
				position: absolute;
				right: 0;
				text-align: center;
				top: calc(50% - 11px);
			}
		}
		&:hover{
			.hover {
				opacity:1;
			}
		
		}
		}
	}
	.trans-overlay-dark-contact{
		@include background-opacity($background_color, 0);
		position:absolute;
		top:0;
		height:100%;
		width:100%;
		z-index:1;
	}

	
	.copyright {
		display:none;
	}
	.form-control {
		&::-webkit-input-placeholder {
		   color: #8d8d8d;
		   font-size:13px;
		   font-family:$secondery_font;
		   text-transform:capitalize;
		}

		&:-moz-placeholder { /* Firefox 18- */
		   color: #8d8d8d;
		   font-size:13px;
		   font-family:$secondery_font;
		   text-transform:capitalize;
		}

		&::-moz-placeholder {  /* Firefox 19+ */
		   color: #8d8d8d;
		   font-size:13px;
		   font-family:$secondery_font;
		   text-transform:capitalize;
		}

		&:-ms-input-placeholder {  
		   color: #8d8d8d;
		   font-size:13px;
		   font-family:$secondery_font;
		   text-transform:capitalize;
		}
		input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
			background-color: transparent;
			background-image: none;
			color: #fff;
		}
	}
}
.detail-info {
	position:relative;
	z-index:2;
	text-align:left;
}


.owl-carousel.owl-theme {
	.owl-item {
			min-height:247px;
			overflow:hidden;
			-webkit-backface-visibility:hidden;
			-webkit-appearance: none;
			@include transition(all .2s ease-in-out);
		}
	
	.owl-controls span {
		background: none;
		border: 2px solid $primery_color;
		border-radius: 0;
		display: inline-block !important;
		height: 12px;
		margin: 5px 7px;
		opacity: 1;
		width: 12px;
	}
	.owl-dot {
		float:left;
	}
	.owl-nav {
		display:none;
	}
	.owl-dot.active span{
		background:#000;
	}
}
#qualities .owl-carousel.owl-theme {
	.owl-item {
			background:$secondery_color;
		}
	
	.owl-item:hover {
			box-shadow: 0 0 0 4px $primery_color inset;
			-webkit-box-shadow: 0 0 0 4px $primery_color inset;
			-ms-box-shadow: 0 0 0 4px $primery_color inset;
			-moz-box-shadow: 0 0 0 4px $primery_color inset;
		}
}


.table {
	display:table;
}

.align-middle {
	display:table-cell;
	vertical-align:middle;
}
.nav-btn {
	font-size:14px;
	text-transform:uppercase;
	position: absolute;
	top: 0;
	z-index:11;
	@include transition(all .6s ease-in-out);
}
@mixin rotate($transform...) {
	 -webkit-transform: $transform;
    -ms-transform: $transform;
    transform: $transform;
}

#qualities,#team{
	color:$primery_color;
	padding-top:52px;
	span {
		display:block;
	}
	.item {
		padding:0 32px;
		overflow:hidden;
	}
	.fa-qual {
		font-size:36px;
		padding-top:28px;
		padding-bottom:5px;
	}
	.qual-tag,.name{
		font-size:18px;
		font-weight:600;
		text-transform:uppercase;
	}
	.qual-desc{
		font-size:12px;
		font-weight:500;
		padding:25px 0;
	}
	
}

.back-left,.back-left:focus,.back-left:hover {
	left: 45px;
	color:$primery_color;
}

.back-right,.back-right:focus,.back-right:hover {
	color:$secondery_color;
	right:45px;
}
.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default.focus:active, .btn-default:active:focus, .btn-default:active:hover, .open > .dropdown-toggle.btn-default.focus, .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
	background:transparent;
	border-color:$primery_color;
	color:$primery_font_color;
	outline:none;
	border: 2px solid;
}
.ani-wrap {
	position:relative;
	overflow:hidden;
}
@keyframes moveUp {
50% {
    transform: translate(0px, -100%);
    -webkit-transform: translate(0px, -100%);
    -ms-transform: translate(0px, -100%);
}
51% {
    opacity: 0;
     transform: translate(0px, -100%);
    -webkit-transform: translate(0px, -100%);
    -ms-transform: translate(0px, -100%);
}
52% {
    opacity: 1;
     transform: translate(0px, 100%);
    -webkit-transform: translate(0px, 100%);
    -ms-transform: translate(0px, 100%);
}
100% {
    transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
}
}
@-webkit-keyframes moveUp {
50% {
    transform: translate(0px, -100%);
    -webkit-transform: translate(0px, -100%);
    -ms-transform: translate(0px, -100%);
}
51% {
    opacity: 0;
     transform: translate(0px, -100%);
    -webkit-transform: translate(0px, -100%);
    -ms-transform: translate(0px, -100%);
}
52% {
    opacity: 1;
     transform: translate(0px, 100%);
    -webkit-transform: translate(0px, 100%);
    -ms-transform: translate(0px, 100%);
}
100% {
    transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
}
}
.btn{
	font-size:16px;
	color:$primery_font_color;
	letter-spacing:1px;
	overflow:hidden;
	position:relative;
	text-transform:uppercase;
	border-color:$primery_color;
	border: 2px solid;
	border-radius:0;
	height:57px;
	background:transparent;
	@include transition(all 0.9s cubic-bezier(0.19, 1, 0.22, 1) 0s);
	 &:hover,&:focus,&:active {
        background:transparent;
		color:$primery_font_color;
		border-color:$primery_color;
		border: 2px solid $primery_color;
		outline:none;
	}
	.button-label {
		padding: 10px 20px;
	}
	span {
			display:inline-block;
			
		}
		.mask {
			background: #fff none repeat scroll 0 0;
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 0;
			z-index:-1;
			@include transition(all 1.1s cubic-bezier(0.19, 1, 0.22, 1) 0s);
	}
	&:hover {
		.mask {
			width: 100%;
		}
	}
}
.black {
	color:$primery_color;
}
.close-notify {
	display: block;
    font-weight: 300;
    margin-bottom: 30px;
    margin-top: 22px;
    width: 41px;

	 &:hover,&:focus,&:active {
       color:$primery_color;
    }
	
}

.notify-cmpt.notify-form-type-2 {
 	 .notify-btn {
		bottom: 0;
		left:0;
		position: relative;
	}
	.notify-wrap {
		#message {
			color: #fff;
			font-family: $secondery_font;
			font-size: 15px;
			line-height: 25px;
		}
		#notify_close {
			cursor: pointer;
			float: left;
			height: 57px;
			margin-right: 15px;
			padding-top: 19px;
			text-align: center;
			width: 25px;
			span {
				color:#fff;
			}
		}
	
		#message {
			padding-left:0;
			padding-top: 10px;
			min-height: 35px;
			color:$primery_color;
		}
		
		.btn {
			border: 2px solid #000;
			border-left:none;
			right: 0;
			top: 0;
			float:left;
			height:57px;
			display:block;
			-webkit-transform:translateZ(0);
			-webkit-backface-visibility: hidden;
			transform:translateZ(0);
			backface-visibility: hidden;
			padding: 0 30px!important;
			.mask {
				background:#000;
			}
			&:hover{
				color:#fff;
			}
			
			}
			
		.notify-input-box {
				background: transparent none repeat scroll 0 0;
				border: 2px solid #000;
				border-radius: 0;
				box-shadow: none;
				width:247px;
				float:left;
				color: $primery_font_color;
				font-size: 16px;
				letter-spacing:1px;
				min-height: 57px;
				padding-left: 13px;
				padding-right: 43px;
			 &:hover,&:focus,&:active {
			    box-shadow:none;
			}
		}
		
		.input__label--hoshi::before,
		.input__label--hoshi::after {
			border-bottom: 4px solid $primery_color;
		}

		.input__label--hoshi::after {
			border-bottom: 8px solid $primery_color;
		}
	}
}
	
.section-title {
	padding-top:11%;
	padding-bottom:18px;
}


.form-horizontal .form-group {
	margin-left:0;
	margin-right:0;
}
.input-wrap {
	position:relative;
	overflow:hidden;
	i {
		display:none;
	}
}
.page-right {
	 input {
		height:80px;
	}
	.map-sec {
		overflow:hidden;
		position:relative;
		height:400px;
		#map_canvas {
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 0;
		}
		a {
			color: #8d8d8d;
			font-size: 13px;
			text-transform: capitalize; 
			text-decoration:underline;
			transition:all .3s ease-in-out;
			-webkit-transition:all .3s ease-in-out;
			&:hover {
				color:#fff;
			}
		}	
	}	
	.bg-danger ,.bg-success{
		background-color:transparent;
		position:absolute;
	}
	.form-control {
		-moz-border-bottom-colors: none;
		-moz-border-left-colors: none;
		-moz-border-right-colors: none;
		-moz-border-top-colors: none;
		background: transparent none repeat scroll 0 0;
		border:none;
		box-shadow: none;
		color: #fff;
		display: block;
		overflow:hidden;
		font-size: 13px;
		font-family:$secondery_font;
		padding-left: 0;
		padding-bottom:18px;
		padding-top: 13px;
		&:focus{
			border-color:$primery_color;
		}
	}
	
	.form-group {
		margin-bottom: 36px;
	}
	.icon {
		padding-left:0;
		i {
			font-size:15px;
		}	
	}

	.btn {
		margin-top:18px;
		padding: 6px 55px;
		border-color:#fff;
		color:#fff;
		&:hover,&:active,&:focus {
			border-color:#fff;
			color:#000;
		}
	}
	

}

@-webkit-keyframes sep-anim {
  0%  {width: 0;}
  100% {width: 20px;}
}

@keyframes sep-anim {
  0%  {width: 0;}
  100% {width: 20px;}
}
.address-detail{
	@extend .align-center;
	margin-top: 60px;
		.col-sm-4 {
			&:hover {
				.add-sep {
					-webkit-animation-name: sep-anim;
					animation-name: sep-anim;
					-webkit-animation-duration: .4s;
					animation-duration: .4s;
					animation-delay:0;
					-webkit-animation-delay:0;
					-webkit-animation-timing-function: ease-in-out;
					animation-timing-function: ease-in-out;
				}	
			}
			span{
				font-size:13px;
				color:#8d8d8d;
				text-transform:capitalize;
			}
		}
	.sep-wrap {
		width:20px;
		margin-bottom: 12px;
		.add-sep {
			background: #fff none repeat scroll 0 0;
			display: block;
			height: 2px;
			text-align: center;
			width: 20px;
		}
	}	
	p{
		font-size:14px;
		font-weight:500;
		padding:0;
		color:#fff;
	}
	text-align:left;
		.icon-detail {
			font-size:14px;
			display:block;
			padding-bottom:10px;
			color:$primery_color;
			text-transform:uppercase;
		}
		a{
			@extend p;
			font-size:14px;
			display:block;
			padding-bottom:0;
			padding-top:0;
		}
		
		i {
			display:block;
		}
}
.fa {
	color:$primery_color;
	-webkit-appearance:none;
	-webkit-backface-visibility:hidden;
}

@mixin border-heightc(){
	-webkit-animation-name: border-heightc;
	animation-name: border-heightc;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	animation-delay:0;
	-webkit-animation-delay:0;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
}
@mixin border-widthc(){
	-webkit-animation-name: border-widthc;
	animation-name: border-widthc;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	animation-delay:0;
	-webkit-animation-delay:0;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
}
@-webkit-keyframes border-widthc {
  0%  {width: 0%;}
  100% {width: 100%;}
}

@keyframes border-widthc {
  0%  {width: 0%;}
  100% {width: 100%;}
}
@-webkit-keyframes border-heightc {
  0% {height: 0%;}
  100% {height: 100%;}
}

@keyframes border-heightc {
  0% {height: 0%;}
  100% {height: 100%;}
}
.call-to-action{
		display: block;
		height: 20px;
		width: 150px;
		div {
			cursor:pointer;
			.add-sep {
				background: #000 none repeat scroll 0 0;
				height: 2px;
				display:block!important;
				text-align: center;
				width: 0;
				@include transition(all 0.3s ease-in-out 0s);
			}
			&:hover {
				.add-sep {
					width:100%;
				}	
			}
			
		}
		.goto-next,.goto-close {
			span {
				display: inline-block;
				position: relative;
			}
			position: absolute;
			display:block;
			right:0;
		}
}
.about {
	left:32px;
	@include rotate(rotate(-90deg));
}

.contact {
	right:32px;
	@include rotate(rotate(270deg));
	span {
				bottom:0;
			}
}
.contact,.about {
	@extend .nav-btn;
	top:163px;
	text-transform:capitalize;
	
	color:$primery_font_color;
}

#astronomy {	
	height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
	#demo-canvas {
		height: 100%;
		left: 0;
		position: absolute;
		width: 100%;
	}	
}	

@keyframes moveclouds {
    0% {
        margin-left: 100%;
    }

    100% {
        margin-left: -100%;
    };
}

@-webkit-keyframes moveclouds {
    0% {
        margin-left: 100%;
    }

    100% {
        margin-left: -100%;
    };
}

@keyframes moveclouds2 {
    0% {
        margin-left: 100%;
    }

    50% {
        margin-left: 100%;
    }

    100% {
        margin-left: -100%;
    };
}

@-webkit-keyframes moveclouds2 {
    0% {
        margin-left: 100%;
    }

    50% {
        margin-left: 100%;
    }

    100% {
        margin-left: -100%;
    };
}
.layer {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
	img{
		max-width:100%;
		height: 100%;
	}
}

.clouds {
	overflow: hidden;
	.cloud {
		bottom: 0;
		left: 0;
		position: absolute;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		width: 100%;
		height: 100%;
		will-change: transform;
	}
	.cloud-1 {
		-webkit-animation: moveclouds 25s linear infinite;
		animation: moveclouds 25s linear infinite;
	}
	.cloud-2 {
		-webkit-animation: moveclouds 50s linear infinite;
		animation: moveclouds 50s linear infinite;
	}
	.cloud-3 {
		-webkit-animation: moveclouds2 35s linear infinite;
		animation: moveclouds2 35s linear infinite;
	}
}

/*****************************************************/
/* Mediaqueries */
/*****************************************************/
@media only screen and (max-width: 1680px) {
	.padding-top-sm-120 {
		padding-top:120px;
	}
}
@media only screen and (max-width: 1600px) {
	h1 {
		font-size:60px;
		line-height:60px;
	}
	.padding-top-md-135 {
		padding-top:135px;
	}
	.padding-top-xs-80 {
		padding-top:80px;
	}
	.contact, .about {
			top: 140px;
		}
		
	.side-left{
		padding-top:75px;
		
		.about {
			left: 6px;
		}
		
		.content-wrap {
			padding-left: 75px;
		}
		.copyright-text {
			bottom: 66px;
		}
		.tag-wrap {
			bottom: 75px
		}
	}
	
	.side-right {
		.contact {
				right:6px;
			}
		.social-wrpper {
			bottom: 66px;
		}	
		.main-content {
			padding-left: 75px;
		}
		.social-wrpper {
			left:75px;
		}
		.notify-btn {
			bottom: 75px;
			right: 75px;
		}
		
		.notify-wrap {
			bottom: 40px;
			right: 75px;
		}
		.sec-pad-right {
			padding-right: 75px;
		}
		.timer-sec {
			bottom: 75px;
			right: 75px;
		}
			
	}	
	.page-right .page-inner .container-fluid,
	.page-left .page-inner .container-fluid
	{
		padding-left: 75px;
		padding-right: 75px;
	}
}
@media only screen and (max-width: 1400px) {
	
	.intro-text {
		clear:both;
	}
	.contact, .about {
		top: 105px;
	}
	.side-left{
		padding-top:40px;
				
		.about {
			left: -28px;
		}
		
		.content-wrap {
			padding-left: 40px;
		}
		.copyright-text {
			bottom: 30px;
		}
		.tag-wrap {
			bottom: 38px
		}
	
	}
	.side-right {
		.contact {
				right:-28px;
			}
		.social-wrpper {
			bottom: 30px;
		}		
		.main-content {
			padding-left: 40px;
		}	
		.social-wrpper {
			left:40px;
		}
		.notify-btn {
			bottom: 40px;
			right: 40px;
		}
		.notify-wrap {
			bottom: 5px;
			right: 40px;
		}
		.timer-sec {
			bottom: 40px;
			right: 40px;
		}
		.sec-pad-right {
			padding-right:40px;
		}
	}	
	.page-right .page-inner .container-fluid,
	.page-left .page-inner .container-fluid
	{
		padding-left: 40px;
		padding-right: 40px;
	}

}
@media only screen and (max-width: 1366px) {
	.padding-top-sm-100 {
		padding-top:100px;
	}
	.padding-top-sm-40 {
		padding-top:40px;
	}
	
	.side-right .twitter-sec{
		width:100%;
		.twiter-ico-wrap {
			margin-right:0;
			margin-bottom:10px;
			float:none;
		}
		#tweecool {
			float:none;
			width:100%;
		}
	}
}
@media only screen and (max-width: 1024px) {
.margin-sm-30 {
	margin-top:30px;
	margin-bottom:30px;
}
.margin-top-xs-30 {
	margin-top:30px;
}
.padding-top-xs-0 {
	padding-top:0px;
}
.padding-top-xs-100 {
	padding-top:100px;
}
.padding-btm-xs-0 {
	padding-bottom:0!important;
}
.margin-top-xs-0 {
	margin-top:0px;
}
.hover {
	display:none;
}
.side-left-overlay,
.side-right-overlay {
		z-index:1;
	}
.timer-sec {
	position:static;
}
.notify-cmpt.notify-form-type-2 .notify-wrap .btn {
	
		&:hover,&:active,&:focus {
			color:#000;
		}
		
}
.side {
	width: 100%;
	&.side-left {
		position: relative;
		min-height:500px;
		.content-wrap {
			z-index:2;
			.tag-wrap {
				position:static;
				padding-top:70px;
			}
		}
		.zs-enabled {
			min-height:500px!important;
			z-index:1;
		}
	}

}
.logo {
    margin-left: 0;
}
.full-height {
	min-height:auto!important;
}
.address-detail {
	.copyright-text {
		display:block;
	}
}

.side.side-right {
    min-height: 600px;
	position:relative;
}
.side-left, .side-right {
    background-attachment: scroll;
}

.logo img {
	width: auto;
}
	
.page {
    position: static;
    width: 100%;
	
	&.page-left {
		-webkit-transform: translateX(0px);
		-ms-transform: translateX(0px);
	    transform: translateX(0px);
		position: static;
	}
	&.page-right {
		left: 0;
		-webkit-transform: translateX(0px);
		-ms-transform: translateX(0px);
	    transform: translateX(0px);
		position: static;
	}
}
	
	.about,.contact {
		display:none;
	}
	.open-left .side-right,
	.open-right .side-left {
	-webkit-transform: translateX(0) scale(1);
	-ms-transform: translateX(0) scale(1);
	    transform: translateX(0) scale(1);
	}

	.open-left .side-left,
	.open-right .side-right
	{
	z-index: 200;
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	    transform: translateX(0);
	}
		
	.open-left .side-left .main,
	.open-right .side-right .main
	{
	-webkit-transform: translateY(0) translateX(0) scale(1);
	-ms-transform: translateY(0) translateX(0) scale(1);
	 transform: translateY(0) translateX(0) scale(1);
	opacity: 1;
    visibility: visible;
	}
	.open-left .page-left,
	.open-right .page-right	{
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	    transform: translateX(0%);
	}
	.reset-layout .page, 
	.splitlayout.open-right .page-left, 
	.splitlayout.open-left .page-right,
	.splitlayout.close-right .page-left, 
	.splitlayout.close-left .page-right {
		position:static;
	}

	.btn:hover .mask {
		width: 0;
	}
	.btn:hover, .btn:focus, .btn:active {
		color:#fff;
	}
	.side-right .social-icons .social-icon:hover span {
		transform: translateY(0px);
		-webkit-transform: translateY(0px);
		-ms-transform: translateY(0px);
	}
}
@media only screen and (max-width: 767px) {
	.margin-btm-xs{
		margin-bottom:30px;
	}
	.margin-bottom-0 {
		margin-bottom:0!important;
	}
	.client-sec {
		.col-xs-6 {
			&:nth-child(1),&:nth-child(2){
				margin-bottom:40px;
			}
		}
	}
	.side{
		
		&.side-right {
			#tweecool {
				min-height:140px;
			}
		}
	}
}
@media only screen and (max-width: 736px) {
		.sec-pad {
			padding-top:50px;
			padding-bottom:50px;
		}
		.sec-pad-top {
			padding-top:50px;
		}
		.sec-pad-bottom {
			padding-bottom:50px;
		}
		.padding-top-xs-50 {
			padding-top:50px;
		}
	.page-right input {
		height: 45px;
	}	
	.logo  {
		img {
			width:135px;
		}
	}
	.side{
		&.side-right {
			min-height: 550px;
		}
		&.side-left {
			min-height:400px;
			.zs-enabled {
				min-height:400px!important;
			}
		}
	}
	.address-detail {
		margin-top: 30px;
	}
}
@media only screen and (max-width: 667px) {
}
@media only screen and (max-width: 568px) {
	h1 {
		font-size:40px;
		line-height:50px;
	}
	
	.margin-sm-30 {
		margin-top:10px;
		margin-bottom:10px;
	}
}	
@media only screen and (max-width: 485px) {
	.side{
		&.side-right {
			min-height:750px;
		}
	}
	.notify-cmpt.notify-form-type-2 .notify-wrap .notify-input-box {
		float:none;
	}
	.notify-cmpt.notify-form-type-2 .notify-wrap .btn {
		border-left:2px solid #000;
		margin-top:20px;
	}
	.notify-cmpt.notify-form-type-2 .notify-wrap .notify-input-box ,
	.notify-cmpt.notify-form-type-2 .notify-wrap .btn {
		width:100%;
		text-align:center;
	}
	.notify-cmpt.notify-form-type-2 .notify-wrap .notify-input-box {
		padding:0;
	}
	
}
@media only screen and (max-width: 414px) {
	h3 {
		padding-bottom:25px;
	}
	.copyright-text {
		.social-icons,p {
			width:100%;
		}
	}
	.side-left #countdown {
		font-size: 14px;
	}	
}
@media only screen and (max-width: 385px) {
.side{
		&.side-right {
			min-height:930px;
		}
	}
}	