@charset "UTF-8";
/*------------------------------------------------------------------
[Master Stylesheet]
Project:	Beavis
++++ Imports ++++
++++ Reset ++++
++++ Main ++++
++++ Mediaqueries ++++
-------------------------------------------------------------------*/
/****************************/
/** Imports **/
/****************************/
@import url(jquery.countdown.css);
@import url(animate.css);
@import url(component2.css);
@import url(font-awesome.min.css);
@import url(et-line-font.css);
@import url(bootstrap.min.css);
@import url(inputtext.css);
@import url(photoswipe.css);
@import url(default-skin/default-skin.css);

/****************************/
/** Reset **/
/****************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
.address-detail a,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

strong {
  font-weight: bold;
}

@font-face {
  font-family: BarlowCondensedMedium;
  src: url('../fonts/BarlowCondensed-Medium.ttf');
}

@font-face {
  font-family: BarlowCondensedRegular;
  src: url('../fonts/BarlowCondensed-Regular.ttf');
}

@font-face {
  font-family: BarlowCondensedSemiBold;
  src: url('../fonts/BarlowCondensed-SemiBold.ttf');
}

@font-face {
  font-family: Helvetica;
  src: url('../fonts/Helvetica.ttc');
}

:root{
  --BarlowCondensedMedium:BarlowCondensedMedium;
  --BarlowCondensedRegular:BarlowCondensedRegular;
  --BarlowCondensedSemiBold:BarlowCondensedSemiBold;
  --Helvetica:Helvetica;
}


body {
  color: #000;
  background: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 1.5;
  overflow-y: auto;
  letter-spacing: 1px;
}

/* Mozilla based browsers */
::-moz-selection {
  background: #f8ec2e;
  color: #000;
}

/* Works in Safari */
::selection {
  background: #f8ec2e;
  color: #000;
}

#ascrail2000 {
  z-index: 999 !important;
}

a:hover,
a:active {
  outline: 0;
}

button:focus {
  border: none;
  outline: 0;
}

ul {
  list-style: none;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #fff;
  text-decoration: none;
}

a:focus {
  color: #fff;
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}

h1 {
  font-size: 72px;
  line-height: 72px;
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: -2px;
  margin-bottom: 15px;
}

h3 {
  font-size: 24px;
  line-height: 35px;
  padding-bottom: 32px;
  color: #fff;
}

h5 {
  font-size: 18px;
  text-transform: uppercase;
  line-height: 25px;
  margin-bottom: 15px;
}

p,
.address-detail a {
  font-size: 15px;
  font-family: var(--Helvetica);
  color: white;
  line-height: 25px;
}

.force-UTF-8 {
  content: "¡";
}

/****************************/
/** Main **/
/****************************/
.pad-zero {
  padding: 0;
}

.pad-left-zero {
  padding-left: 0;
}

.pad-right-zero {
  padding-left: 0;
}

.sec-pad {
  padding-top: 100px;
  padding-bottom: 100px;
}

.sec-pad-sm {
  padding-top: 50px;
  padding-bottom: 50px;
}

.sec-pad-xs {
  padding-top: 30px;
  padding-bottom: 30px;
}

.sec-pad-top {
  padding-top: 100px;
}

.sec-pad-top-sm {
  padding-top: 50px;
}

.sec-pad-top-xs {
  padding-top: 30px;
}

.sec-pad-bottom {
  padding-bottom: 100px;
}

.sec-pad-bottom-sm {
  padding-bottom: 100px;
}

.sec-pad-bottom-xs {
  padding-bottom: 30px;
}

.sec-pad-left {
  padding-left: 100px;
}

.sec-pad-right {
  padding-right: 100px;
}

.padding-top-lg-175 {
  padding-top: 175px;
}

.padding-top-lg-170 {
  padding-top: 170px;
}

.padding-top-sm {
  padding-top: 60px;
}

.margin-btm-lg {
  margin-bottom: 47px;
}

.margin-top-sm {
  margin-top: 35px;
}

.margin-btm-sm {
  margin-bottom: 30px;
}

.margin-top-xs-20 {
  margin-top: 20px;
}

.opacity-hide {
  opacity: 0;
  visibility: hidden;
}

.preloader-it {
  background: #fff;
  position: fixed;
  z-index: 110;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
  display: none;
}

/* Effect 1: YouTube loading */
.la-anim-1 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 10px;
  background: #000;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  pointer-events: none;
}

.la-anim-1::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100%;
  background: #000;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.2s, box-shadow 2s 4s;
  transition: opacity 0.2s, box-shadow 2s 4s;
  -webkit-transform: rotate(2deg) translateY(-2px);
  transform: rotate(2deg) translateY(-2px);
}

.la-anim-1.la-animate {
  z-index: 100;
  opacity: 1;
  -webkit-transition: -webkit-transform 5s ease-in, opacity 1s 5s;
  transition: transform 5s ease-in, opacity 1s 5s;
  -webkit-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.la-anim-1.la-animate::after {
  opacity: 1;
}

.side-left-overlay,
.side-right-overlay {
  position: absolute;
  top: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
}

@-webkit-keyframes back_ken {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.4);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes back_ken {
  0% {
    -moz-transform: scale(1);
  }

  50% {
    -moz-transform: scale(1.4);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@-o-keyframes back_ken {
  0% {
    -o-transform: scale(1);
  }

  50% {
    -o-transform: scale(1.4);
  }

  100% {
    -o-transform: scale(1);
  }
}

@keyframes back_ken {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

.side-left {
  padding-top: 100px;
}

.side-left .leftken {
  position: absolute;
  top: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  background: url("../img/img-s.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.side-left p,
.side-left .address-detail a,
.address-detail .side-left a {
  color: #fff;
  margin: -13px;
}

.side-left .small-hr {
  margin-top: 17px;
  margin-bottom: 25px;
}

.side-left .tag-wrap {
  position: absolute;
  bottom: 97px;
}

.side-left footer {
  float: left;
}

.side-left #countdown {
  color: #fff;
  font-size: 18px;
  margin-top: 26px;
}

.side-left .content-wrap {
  height: 100%;
  padding-left: 100px;
}

.preloader {
  background: #fff;
}

p.tagline,
.address-detail a.tagline {
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: -1px;
}

.side-right {
  background: #fff;
}

.social-icons {
  float: left;
  margin-right: 22px;


}

.social-icons .fa {
  color: #000;

}

.social-icons li {
  display: inline-block;
  float: left;
  margin-right: 20px;
}

.social-icons li:last-child {
  margin-right: 0;
}

.social-icons .social-icon {
  color: #000;
  font-size: 18px;
  overflow: hidden;
  text-transform: uppercase;
  line-height: 25px;
  position: relative;
  display: inline-block;
}

.social-icons .social-icon span {
  display: inline-block;
  position: relative;
  -moz-transition: transform 0.3s ease 0s;
  -o-transition: transform 0.3s ease 0s;
  -webkit-transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s;
}

.social-icons .social-icon span.fa {
  line-height: 25px;
}

.social-icons .social-icon span:before {
  content: attr(data-hover);
  position: absolute;
  top: 100%;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
}

.social-icons .social-icon:hover span {
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
}

.social-icons .social-icon:hover i.fa-facebook {
  color: #3B5998;
}

.social-icons .social-icon:hover i.fa-twitter {
  color: #00aced;
}

.social-icons .social-icon:hover i.fa-linkedin {
  color: #007bb6;
}

.social-icons .social-icon:hover i.fa-behance {
  color: #1769ff;
}

.side-right {
  color: rgb(0, 0, 0);
  right: 0;
  /* background-color: #fce84d; */
  background-image: url('../img/bg.png');
  background-repeat: no-repeat;
  background-size: cover;

}

.side-right .social-wrpper {
  bottom: 92px;
  left: 100px;
  position: absolute;
  width: 100%;
  z-index: 111;
}

.side-right .copyright-text p,
.side-right .copyright-text .address-detail a,
.address-detail .side-right .copyright-text a {
  font-size: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #8d8d8d;
  float: left;
}

.side-right .twitter-sec {
  width: 600px;
  float: left;
}

.side-right .twitter-sec .twiter-ico-wrap {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  display: block;
  float: left;
  margin-right: 14px;
  height: 50px;
  text-align: center;
  width: 50px;
}

.side-right .twitter-sec .twiter-ico-wrap i {
  padding-top: 16px;
}

.side-right .twitter-sec #tweecool {
  min-height: 125px;
  position: relative;
  font-family: GothamBook;
  font-size: 16px;
  line-height: 27px;
  width: 80%;
  float: left;
}

.side-right .twitter-sec #tweecool ul li {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.side-right .twitter-sec #tweecool ul li.current {
  opacity: 1;
  visibility: visible;
}

.side-right .twitter-sec #tweecool ul li img {
  display: none;
}

.side-right .twitter-sec #tweecool ul li span {
  display: block;
  font-size: 13px;
  color: #8d8d8d;
  padding-top: 2px;
}

.form-control.notify-input-box::-webkit-input-placeholder {
  color: #000;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 1;
}

.form-control.notify-input-box:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 1;
}

.form-control.notify-input-box::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 1;
}

.form-control.notify-input-box:-ms-input-placeholder {
  color: #000;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 1;
}

.align-center,
.address-detail {
  overflow: hidden;
  clear: both;
  float: none;
  margin: 0 auto;
}

.logo {
  display: inline-block;
  margin-top: -20px;
  margin-left: -30px;
}

.type-wrap {
  min-height: 120px;
  padding-bottom: 40px;
}

.small-hr {
  background: #000 none repeat scroll 0 0;
  display: block;
  height: 4px;
  width: 32px;
}

.bg-img,
.page-right .page-inner {

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.page-left .page-inner .container-fluid {
  padding-left: 100px;
  padding-right: 100px;
}

.page-left .page-inner .small-hr {
  margin-top: 9px;
  margin-bottom: 23px;
}

.page-left .page-inner .trans-overlay-dark-about {
  background: #fff;
  /* The Fallback */
  background: rgba(255, 255, 255, 0);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.trans-overlay-dark {
  background: #fff;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.project-gal-wrap img {
  width: 100%;
}

.pswp__bg {
  background: rgba(0, 0, 0, 0.95) none repeat scroll 0 0;
}

.pswp__caption {
  bottom: 130px;
}

.pswp__caption__center {
  color: #fff;
  font-size: 15px;
  font-family: GothamBook;
  text-align: center;
  text-transform: capitalize;
}

.page-right .page-inner {
  background: #000;
}

.page-right .page-inner .container-fluid {
  padding-left: 100px;
  padding-right: 100px;
}

.page-right .page-inner .panel-body {
  padding-top: 0;
}

.page-right .page-inner .client-sec .col-sm-3 {
  text-align: center;
}

.page-right .page-inner .client-sec .col-sm-3 img {
  display: inline-block;
}

.page-right .page-inner .about-sec .about-icon-wrap span {
  display: block;
}

.page-right .page-inner .about-sec .about-icon-wrap span.key-fact {
  font-size: 16px;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 14px;
  margin-top: 25px;
}

.page-right .page-inner .about-sec .about-icon-wrap .icon {
  color: #cacaca;
  font-size: 48px;
}

.page-right .page-inner .team-sec span {
  display: block;
}

.page-right .page-inner .team-sec .team-per {
  font-size: 13px;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 3px;
  margin-top: 20px;
}

.page-right .page-inner .team-sec .team-desn {
  font-size: 12px;
  color: #8d8d8d;
  text-transform: capitalize;
  margin-bottom: 17px;
}

.page-right .page-inner .team-sec .team-social {
  float: left;
}

.page-right .page-inner .team-sec .team-social li {
  margin-right: 12px;
  float: left;
}

.page-right .page-inner .team-sec .team-social li a i {
  color: #fff;
  font-size: 12px;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-right .page-inner .team-sec .team-social li a:hover i {
  color: #8d8d8d;
}

.page-right .page-inner .project-gal-wrap {
  margin-top: 60px;
}

.page-right .page-inner .pswp__caption__center {
  display: none;
}

.page-right .page-inner figure figcaption {
  font-family: GothamBook;
  font-size: 12px;
  text-transform: capitalize;
  color: #8d8d8d;
  padding: 20px 0 0 0;
}

.page-right .page-inner figure a {
  display: block;
  overflow: hidden;
  position: relative;
}

.page-right .page-inner figure a .hover {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.page-right .page-inner figure a .hover span {
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: calc(50% - 11px);
}

.page-right .page-inner figure a:hover .hover {
  opacity: 1;
}

.page-right .page-inner .trans-overlay-dark-contact {
  background: #fff;
  /* The Fallback */
  background: rgba(255, 255, 255, 0);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.page-right .page-inner .copyright {
  display: none;
}

.page-right .page-inner .form-control::-webkit-input-placeholder {
  color: #8d8d8d;
  font-size: 13px;
  font-family: GothamBook;
  text-transform: capitalize;
}

.page-right .page-inner .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #8d8d8d;
  font-size: 13px;
  font-family: GothamBook;
  text-transform: capitalize;
}

.page-right .page-inner .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #8d8d8d;
  font-size: 13px;
  font-family: GothamBook;
  text-transform: capitalize;
}

.page-right .page-inner .form-control:-ms-input-placeholder {
  color: #8d8d8d;
  font-size: 13px;
  font-family: GothamBook;
  text-transform: capitalize;
}

.page-right .page-inner .form-control input:-webkit-autofill,
.page-right .page-inner .form-control textarea:-webkit-autofill,
.page-right .page-inner .form-control select:-webkit-autofill {
  background-color: transparent;
  background-image: none;
  color: #fff;
}

.detail-info {
  position: relative;
  z-index: 2;
  text-align: left;
}

.owl-carousel.owl-theme .owl-item {
  min-height: 247px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-appearance: none;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.owl-carousel.owl-theme .owl-controls span {
  background: none;
  border: 2px solid #000;
  border-radius: 0;
  display: inline-block !important;
  height: 12px;
  margin: 5px 7px;
  opacity: 1;
  width: 12px;
}

.owl-carousel.owl-theme .owl-dot {
  float: left;
}

.owl-carousel.owl-theme .owl-nav {
  display: none;
}

.owl-carousel.owl-theme .owl-dot.active span {
  background: #000;
}

#qualities .owl-carousel.owl-theme .owl-item {
  background: #fff;
}

#qualities .owl-carousel.owl-theme .owl-item:hover {
  box-shadow: 0 0 0 4px #000 inset;
  -webkit-box-shadow: 0 0 0 4px #000 inset;
  -ms-box-shadow: 0 0 0 4px #000 inset;
  -moz-box-shadow: 0 0 0 4px #000 inset;
}

.table {
  display: table;
}

.align-middle {
  display: table-cell;
  vertical-align: middle;
}

.nav-btn,
.contact,
.about {
  font-size: 14px;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  z-index: 11;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

#qualities,
#team {
  color: #000;
  padding-top: 52px;
}

#qualities span,
#team span {
  display: block;
}

#qualities .item,
#team .item {
  padding: 0 32px;
  overflow: hidden;
}

#qualities .fa-qual,
#team .fa-qual {
  font-size: 36px;
  padding-top: 28px;
  padding-bottom: 5px;
}

#qualities .qual-tag,
#qualities .name,
#team .qual-tag,
#team .name {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

#qualities .qual-desc,
#team .qual-desc {
  font-size: 12px;
  font-weight: 500;
  padding: 25px 0;
}

.back-left,
.back-left:focus,
.back-left:hover {
  left: 45px;
  color: #000;
}

.back-right,
.back-right:focus,
.back-right:hover {
  color: #fff;
  right: 45px;
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default.focus:active,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.dropdown-toggle.btn-default.focus,
.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:hover {
  background: transparent;
  border-color: #000;
  color: #000;
  outline: none;
  border: 2px solid;
}

.ani-wrap {
  position: relative;
  overflow: hidden;
}

@keyframes moveUp {
  50% {
    transform: translate(0px, -100%);
    -webkit-transform: translate(0px, -100%);
    -ms-transform: translate(0px, -100%);
  }

  51% {
    opacity: 0;
    transform: translate(0px, -100%);
    -webkit-transform: translate(0px, -100%);
    -ms-transform: translate(0px, -100%);
  }

  52% {
    opacity: 1;
    transform: translate(0px, 100%);
    -webkit-transform: translate(0px, 100%);
    -ms-transform: translate(0px, 100%);
  }

  100% {
    transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes moveUp {
  50% {
    transform: translate(0px, -100%);
    -webkit-transform: translate(0px, -100%);
    -ms-transform: translate(0px, -100%);
  }

  51% {
    opacity: 0;
    transform: translate(0px, -100%);
    -webkit-transform: translate(0px, -100%);
    -ms-transform: translate(0px, -100%);
  }

  52% {
    opacity: 1;
    transform: translate(0px, 100%);
    -webkit-transform: translate(0px, 100%);
    -ms-transform: translate(0px, 100%);
  }

  100% {
    transform: translate(0px, 0px);
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
  }
}

.btn {
  font-size: 16px;
  color: #000;
  letter-spacing: 1px;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  border-color: #000;
  border: 2px solid;
  border-radius: 0;
  height: 57px;
  background: transparent;
  -moz-transition: all 0.9s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -o-transition: all 0.9s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -webkit-transition: all 0.9s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transition: all 0.9s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

.btn:hover,
.btn:focus,
.btn:active {
  background: transparent;
  color: #000;
  border-color: #000;
  border: 2px solid #000;
  outline: none;
}

.btn .button-label {
  padding: 10px 20px;
}

.btn span {
  display: inline-block;
}

.btn .mask {
  background: #fff none repeat scroll 0 0;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: -1;
  -moz-transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -o-transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -webkit-transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

.btn:hover .mask {
  width: 100%;
}

.black {
  color: #000;
}

.close-notify {
  display: block;
  font-weight: 300;
  margin-bottom: 30px;
  margin-top: 22px;
  width: 41px;
}

.close-notify:hover,
.close-notify:focus,
.close-notify:active {
  color: #000;
}

.notify-cmpt.notify-form-type-2 .notify-btn {
  bottom: 0;
  left: 0;
  position: relative;
}

.notify-cmpt.notify-form-type-2 .notify-wrap #message {
  color: #fff;
  font-family: GothamBook;
  font-size: 15px;
  line-height: 25px;
}

.notify-cmpt.notify-form-type-2 .notify-wrap #notify_close {
  cursor: pointer;
  float: left;
  height: 57px;
  margin-right: 15px;
  padding-top: 19px;
  text-align: center;
  width: 25px;
}

.notify-cmpt.notify-form-type-2 .notify-wrap #notify_close span {
  color: #fff;
}

.notify-cmpt.notify-form-type-2 .notify-wrap #message {
  padding-left: 0;
  padding-top: 10px;
  min-height: 35px;
  color: #000;
}

.notify-cmpt.notify-form-type-2 .notify-wrap .btn {
  border: none;
  border-left: none;
  right: 0;
  top: 0;
  float: left;
  height: auto;
  display: block;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  backface-visibility: hidden;
  padding: 10px 60px !important;
  margin-top: 20px;
  outline: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  margin-right: 20px;
  width: 150px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
  font-family: var(--BarlowCondensedSemiBold);
  color: #000;
  transition: 0.5s;
}

.notify-cmpt.notify-form-type-2 .notify-wrap .btn:hover{
background-color: #3785fa !important;
color: #fff;
}

.notify-cmpt.notify-form-type-2 .notify-wrap .btn:focus {
  box-shadow: none;
}

.notify-cmpt.notify-form-type-2 .notify-wrap .btn .mask {
  background: #000;
  display: none;
}

/* .notify-cmpt.notify-form-type-2 .notify-wrap .btn:hover {
  color: #fff;

} */

.notify-cmpt.notify-form-type-2 .notify-wrap .notify-input-box {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #fff;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  float: left;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  min-height: 40px;
  padding-left: 13px;
  padding-right: 43px;
  margin-bottom: 14px;
}

.notify-cmpt.notify-form-type-2 .notify-wrap .notify-input-box:hover,
.notify-cmpt.notify-form-type-2 .notify-wrap .notify-input-box:focus,
.notify-cmpt.notify-form-type-2 .notify-wrap .notify-input-box:active {
  box-shadow: none;
}

.notify-cmpt.notify-form-type-2 .notify-wrap .input__label--hoshi::before,
.notify-cmpt.notify-form-type-2 .notify-wrap .input__label--hoshi::after {
  border-bottom: 4px solid #000;
}

.notify-cmpt.notify-form-type-2 .notify-wrap .input__label--hoshi::after {
  border-bottom: 8px solid #000;
}

.section-title {
  padding-top: 11%;
  padding-bottom: 18px;
}

.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}

.input-wrap {
  position: relative;
  overflow: hidden;
}

.input-wrap i {
  display: none;
}

.page-right input {
  height: 80px;
}

.page-right .map-sec {
  overflow: hidden;
  position: relative;
  height: 400px;
}

.page-right .map-sec #map_canvas {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.page-right .map-sec a {
  color: #8d8d8d;
  font-size: 13px;
  text-transform: capitalize;
  text-decoration: underline;
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
}

.page-right .map-sec a:hover {
  color: #fff;
}

.page-right .bg-danger,
.page-right .bg-success {
  background-color: transparent;
  position: absolute;
}

.page-right .form-control {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: transparent none repeat scroll 0 0;
  border: none;
  box-shadow: none;
  color: #fff;
  display: block;
  overflow: hidden;
  font-size: 13px;
  font-family: GothamBook;
  padding-left: 0;
  padding-bottom: 18px;
  padding-top: 13px;
}

.page-right .form-control:focus {
  border-color: #000;
}

.page-right .form-group {
  margin-bottom: 36px;
}

.page-right .icon {
  padding-left: 0;
}

.page-right .icon i {
  font-size: 15px;
}

.page-right .btn {
  margin-top: 18px;
  padding: 6px 55px;
  border-color: #fff;
  color: #fff;
}

.page-right .btn:hover,
.page-right .btn:active,
.page-right .btn:focus {
  border-color: #fff;
  color: #000;
}

@-webkit-keyframes sep-anim {
  0% {
    width: 0;
  }

  100% {
    width: 20px;
  }
}

@keyframes sep-anim {
  0% {
    width: 0;
  }

  100% {
    width: 20px;
  }
}

.address-detail {
  margin-top: 60px;
  text-align: left;
}

.address-detail .col-sm-4:hover .add-sep {
  -webkit-animation-name: sep-anim;
  animation-name: sep-anim;
  -webkit-animation-duration: .4s;
  animation-duration: .4s;
  animation-delay: 0;
  -webkit-animation-delay: 0;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.address-detail .col-sm-4 span {
  font-size: 13px;
  color: #8d8d8d;
  text-transform: capitalize;
}

.address-detail .sep-wrap {
  width: 20px;
  margin-bottom: 12px;
}

.address-detail .sep-wrap .add-sep {
  background: #fff none repeat scroll 0 0;
  display: block;
  height: 2px;
  text-align: center;
  width: 20px;
}

.address-detail p,
.address-detail a {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  color: #fff;
}

.address-detail .icon-detail {
  font-size: 14px;
  display: block;
  padding-bottom: 10px;
  color: #000;
  text-transform: uppercase;
}

.address-detail a {
  font-size: 14px;
  display: block;
  padding-bottom: 0;
  padding-top: 0;
}

.address-detail i {
  display: block;
}

.fa {
  color: #000;
  -webkit-appearance: none;
  -webkit-backface-visibility: hidden;
}

@-webkit-keyframes border-widthc {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes border-widthc {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@-webkit-keyframes border-heightc {
  0% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

@keyframes border-heightc {
  0% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

.call-to-action {
  display: block;
  height: 20px;
  width: 150px;
}

.call-to-action div {
  cursor: pointer;
}

.call-to-action div .add-sep {
  background: #000 none repeat scroll 0 0;
  height: 2px;
  display: block !important;
  text-align: center;
  width: 0;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.call-to-action div:hover .add-sep {
  width: 100%;
}

.call-to-action .goto-next,
.call-to-action .goto-close {
  position: absolute;
  display: block;
  right: 0;
}

.call-to-action .goto-next span,
.call-to-action .goto-close span {
  display: inline-block;
  position: relative;
}

.about {
  left: 32px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.contact {
  right: 32px;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.contact span {
  bottom: 0;
}

.contact,
.about {
  top: 163px;
  text-transform: capitalize;
  color: #000;
}

#astronomy {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

#astronomy #demo-canvas {
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}

@keyframes moveclouds {
  0% {
    margin-left: 100%;
  }

  100% {
    margin-left: -100%;
  }
}

@-webkit-keyframes moveclouds {
  0% {
    margin-left: 100%;
  }

  100% {
    margin-left: -100%;
  }
}

@keyframes moveclouds2 {
  0% {
    margin-left: 100%;
  }

  50% {
    margin-left: 100%;
  }

  100% {
    margin-left: -100%;
  }
}

@-webkit-keyframes moveclouds2 {
  0% {
    margin-left: 100%;
  }

  50% {
    margin-left: 100%;
  }

  100% {
    margin-left: -100%;
  }
}

.layer {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

.layer img {
  max-width: 100%;
  height: 100%;
}

.clouds {
  overflow: hidden;
}

.clouds .cloud {
  bottom: 0;
  left: 0;
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100%;
  height: 100%;
  will-change: transform;
}

.clouds .cloud-1 {
  -webkit-animation: moveclouds 25s linear infinite;
  animation: moveclouds 25s linear infinite;
}

.clouds .cloud-2 {
  -webkit-animation: moveclouds 50s linear infinite;
  animation: moveclouds 50s linear infinite;
}

.clouds .cloud-3 {
  -webkit-animation: moveclouds2 35s linear infinite;
  animation: moveclouds2 35s linear infinite;
}

/*****************************************************/
/* Mediaqueries */
/*****************************************************/
@media only screen and (max-width: 1680px) {
  .padding-top-sm-120 {
    padding-top: 120px;
  }
}

@media only screen and (max-width: 1600px) {
  h1 {
    font-size: 60px;
    line-height: 60px;
  }

  .padding-top-md-135 {
    padding-top: 135px;
  }

  .padding-top-xs-80 {
    padding-top: 80px;
  }

  .contact,
  .about {
    top: 140px;
  }

  .side-left {
    padding-top: 75px;
  }

  .side-left .about {
    left: 6px;
  }

  .side-left .content-wrap {
    padding-left: 75px;
  }

  .side-left .copyright-text {
    bottom: 66px;
  }

  .side-left .tag-wrap {
    bottom: 75px;
  }

  .side-right .contact {
    right: 6px;
  }

  .side-right .social-wrpper {
    bottom: 66px;
  }

  .side-right .main-content {
    padding-left: 75px;
  }

  .side-right .social-wrpper {
    left: 75px;
  }

  .side-right .notify-btn {
    bottom: 75px;
    right: 75px;
  }

  .side-right .notify-wrap {
    bottom: 40px;
    right: 75px;
  }

  .side-right .sec-pad-right {
    padding-right: 75px;
  }

  .side-right .timer-sec {
    bottom: 75px;
    right: 75px;
  }

  .page-right .page-inner .container-fluid,
  .page-left .page-inner .container-fluid {
    padding-left: 75px;
    padding-right: 75px;
  }
}

@media only screen and (max-width: 1400px) {
  .intro-text {
    clear: both;
  }

  .contact,
  .about {
    top: 105px;
  }

  .side-left {
    padding-top: 0;
  }

  .side-left .about {
    left: -28px;
  }

  .side-left .content-wrap {
    padding-left: 40px;
  }

  .side-left .copyright-text {
    bottom: 30px;
  }

  .side-left .tag-wrap {
    bottom: 38px;
  }

  .side-right .contact {
    right: -28px;
  }

  .side-right .social-wrpper {
    bottom: 14px;
  }

  .side-right .main-content {
    padding-left: 20px;
  }

  .side-right .social-wrpper {
    left: 0;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: 100%;
  }


  .side-right .notify-btn {
    bottom: 40px;
    right: 40px;
  }

  .side-right .notify-wrap {
    bottom: 5px;
    right: 40px;
  }

  .side-right .timer-sec {
    bottom: 40px;
    right: 40px;
  }

  .side-right .sec-pad-right {
    padding-right: 20px;
  }

  .page-right .page-inner .container-fluid,
  .page-left .page-inner .container-fluid {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 1366px) {
  .padding-top-sm-100 {
    padding-top: 100px;
  }

  .padding-top-sm-40 {
    padding-top: 20px;
  }

  .side-right .twitter-sec {
    width: 100%;
  }

  .side-right .twitter-sec .twiter-ico-wrap {
    margin-right: 0;
    margin-bottom: 10px;
    float: none;
  }

  .side-right .twitter-sec #tweecool {
    float: none;
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .margin-sm-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .margin-top-xs-30 {
    margin-top: 30px;
  }

  .padding-top-xs-0 {
    padding-top: 0px;
  }

  .padding-top-xs-100 {
    padding-top: 100px;
  }

  .padding-btm-xs-0 {
    padding-bottom: 0 !important;
  }

  .margin-top-xs-0 {
    margin-top: 0px;
  }

  .hover {
    display: none;
  }

  .side-left-overlay,
  .side-right-overlay {
    z-index: 1;
  }

  .timer-sec {
    position: static;
  }

  .notify-cmpt.notify-form-type-2 .notify-wrap .btn:hover,
  .notify-cmpt.notify-form-type-2 .notify-wrap .btn:active,
  .notify-cmpt.notify-form-type-2 .notify-wrap .btn:focus {
    color: #000;
  }

  .side {
    width: 100%;
  }

  .side.side-left {
    position: relative;
    min-height: 500px;
  }

  .side.side-left .content-wrap {
    z-index: 2;
  }

  .side.side-left .content-wrap .tag-wrap {
    position: static;
    padding-top: 70px;
  }

  .side.side-left .zs-enabled {
    min-height: 500px !important;
    z-index: 1;
  }

  .logo {
    margin-left: 0;
  }

  .full-height {
    min-height: auto !important;
  }



  .address-detail .copyright-text {
    display: block;
  }

  .side.side-right {
    min-height: 600px;
    position: relative;
  }

  .side-left,
  .side-right {
    background-attachment: scroll;
  }

  .logo img {
    width: auto;
  }

  .page {
    position: static;
    width: 100%;
  }

  .page.page-left {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
    position: static;
  }

  .page.page-right {
    left: 0;
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
    position: static;
  }

  .about,
  .contact {
    display: none;
  }

  .open-left .side-right,
  .open-right .side-left {
    -webkit-transform: translateX(0) scale(1);
    -ms-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }

  .open-left .side-left,
  .open-right .side-right {
    z-index: 200;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  .open-left .side-left .main,
  .open-right .side-right .main {
    -webkit-transform: translateY(0) translateX(0) scale(1);
    -ms-transform: translateY(0) translateX(0) scale(1);
    transform: translateY(0) translateX(0) scale(1);
    opacity: 1;
    visibility: visible;
  }

  .open-left .page-left,
  .open-right .page-right {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
  }

  .reset-layout .page,
  .splitlayout.open-right .page-left,
  .splitlayout.open-left .page-right,
  .splitlayout.close-right .page-left,
  .splitlayout.close-left .page-right {
    position: static;
  }

  .btn:hover .mask {
    width: 0;
  }

  .btn:hover,
  .btn:focus,
  .btn:active {
    color: #fff;
  }

  .side-right .social-icons .social-icon:hover span {
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
  }
}

@media only screen and (max-width: 767px) {
  .margin-btm-xs {
    margin-bottom: 30px;
  }

  .margin-bottom-0 {
    margin-bottom: 0 !important;
  }

  .client-sec .col-xs-6:nth-child(1),
  .client-sec .col-xs-6:nth-child(2) {
    margin-bottom: 40px;
  }

  .side.side-right #tweecool {
    min-height: 140px;
  }
}

@media only screen and (max-width: 736px) {
  .sec-pad {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .sec-pad-top {
    padding-top: 50px;
  }

  .sec-pad-bottom {
    padding-bottom: 50px;
  }

  .padding-top-xs-50 {
    padding-top: 50px;
  }

  .page-right input {
    height: 45px;
  }

  .logo img {
    width: 135px;
  }

  .side.side-right {
    min-height: 550px;
  }

  .side.side-left {
    min-height: 400px;
  }

  .side.side-left .zs-enabled {
    min-height: 400px !important;
  }

  .address-detail {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 568px) {
  h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .margin-sm-30 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 485px) {
  .side.side-right {
    min-height: 750px;
  }

  .notify-cmpt.notify-form-type-2 .notify-wrap .notify-input-box {
    float: none;
  }

  .notify-cmpt.notify-form-type-2 .notify-wrap .btn {
    border-left: 2px solid #000;
    margin-top: 20px;
  }

  .notify-cmpt.notify-form-type-2 .notify-wrap .notify-input-box,
  .notify-cmpt.notify-form-type-2 .notify-wrap .btn {
    width: 100%;
    text-align: center;
  }

  .notify-cmpt.notify-form-type-2 .notify-wrap .notify-input-box {
    padding: 0;
  }
}

@media only screen and (max-width: 414px) {
  h3 {
    padding-bottom: 25px;
  }

  .copyright-text .social-icons,
  .copyright-text p,
  .copyright-text .address-detail a,
  .address-detail .copyright-text a {
    width: 100%;
  }

  .side-left #countdown {
    font-size: 14px;
  }
}

@media only screen and (max-width: 385px) {
  .side.side-right {
    min-height: 930px;
  }
}







.chkbox {
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 15px;
  color: white;
}

.safola {
  text-align: center;
  margin-top: 20px;


}

.btn-box {
  text-align: center;
  margin-top: 20px;


}

.rubish {

  padding: 10px 35px;
  font-size: 15px;
  background-color: #f8ec2e;
  border: none;
  border-radius: 20px;
}

.roder {

  color: rgb(206, 39, 39);
}

.nxt-pre a {

  float: right;
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
  margin: 5px;
  background-color: #f8ec2e;
  border: 1px solid black;
  border-radius: 40%;

}


.nxt-pre {

  padding-right: 90px;


}

.logo img {
  width: 120px;
  margin-top: 20px;
}


.quiz-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.Quiz_box {

  padding: 0;
  display: flex;
  width: 100%;
}

.Quiz_box .left_section {
  width: 100%;
}

.Quiz_box .right_section {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Quiz_box .question {
  font-size: 16px;
  font-family: var(--Helvetica);
  margin-bottom: 16px;
  letter-spacing: 1px;
  color: #000;
}

.Quiz_box .answer_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 25px;
  padding-top: 20px;
}

.Quiz_box .answer_list button {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #000;
  background: #fff;
  width: 55%;
  margin: 10px 0;
  padding: 10px 15px;
  border: none;
  outline: 2px solid #fff;
  color: #000;
  letter-spacing: 1px;
  transition: 0.5s;
  font-family: var(--Helvetica);

}

.Quiz_box .answer_list button:hover {
  box-shadow: 0 0 15px rgba(225, 225, 225, 1);
}

.Quiz_box .answer_list button.selected {
  box-shadow: 0 0 15px rgba(225, 225, 225, 1);
}


.Quiz_box .answer_list button span {
  position: relative;
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #3785fa;
  border-radius: 50%;
  margin-right: 10px;
}



.Quiz_box .answer_list button span.active {
  border: 8px solid #625720 ;
  background: #fff;
}

.button_group {
  display: flex;
  width: 100%;
  justify-content: start;
  width: 100%;
  margin-top: 0;
  padding-left: 23px;
}

.button_group button {
  display: flex;
  align-items: center;
  justify-content: center;

}

.button_group button img {
  width: 60%;
}

.wering {
  margin-top: 60px;
  color: white;
}


.spin-1 {
  color: #000;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spin-1 span {
  color: #000 !important;
  font-family: var(--Helvetica);
  font-size: 16px;
  display: block;
  margin-bottom: 10px;
}

.spin-1 button {
  padding: 10px 14px;
  border-radius: 0;
  border: none;
  background: #000;
  color: #fff !important;
  font-family: var(--Helvetica);

}

.Spin {
  font-size: 50px;
  color: blue;
}

.intro {
  position: relative;
  z-index: 1;
}

.wheeler {
  min-width: 400px;
  min-height: 400px;
  /* background-color: red; */
  position: absolute;
  left: 33%;
  top: 15%;
  z-index: 9999;
}

.full_height {
  height: 100%;
  width: 100%;
  /* padding: 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.full-height .banner_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer_link {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin-bottom: 0;
}

.footer_link li {
  padding: 0 6px;
  color: #000;
  font-family: var(--Helvetica);
  font-size: 12px;
  padding-top: 3px;
}


.footer_link li a {
  text-decoration: none;
  padding-right: 0;
  font-family: var(--Helvetica);
  color: #000;
  font-size: 12px;
}

.right_section_page {
  position: relative;
  padding: 20px;
  height: 100%;
  z-index: 1111;
}

.right_section_page h3 {
  font-size: 20px;
  color: #fff;
  line-height: 24px;
  margin-bottom: 0;
}

.form-control,
select,
textarea {
  display: block;
  width: 100%;
  height: auto !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
  line-height: 1.42857143;

  background-color: transparent !important;
  background-image: none;
  border: 1px solid #fff !important;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  font-family: var(--BarlowCondensedRegular);
  letter-spacing: 1px;
}

label {
  font-family: var(--BarlowCondensedRegular) !important;
  font-size: 14px !important;
}

.form-control,
textarea {
  color: #fff !important;
}

.form-control:focus,
select:focus,
textarea:focus {
  box-shadow: none !important;
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
  color: #fff !important;
}

.form-control::placeholder,
select::placeholder,
textarea::placeholder {
  color: #fff !important;
}

.form-check-input:checked {
  background-color: #000 !important;
  border-color: #000 !important;
}

.contact-form {
  position: relative;
}

.field_input .mb-3 {
  margin-bottom: 20px;
}

select {
  color: #fff;
}

select {
  color: #fff !important;
}

option {
  color: #000;
  /* Change option color */
}

.step_2_form {
  margin-top: 0;
  /* background: #ffffff42; */
  padding: 0;
  border-radius: 10px;
  height: 100%;
}

.side.side-right {
  height: 100%;
}

.complete_section {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

}

.complete_section h2 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 20px;
}

.complete_section button {
  margin-top: 5px;
  color: #fff;
  padding: 12px 40px;
  border-radius: 5px;
  background-color: #625720;
}

.shine {
  -webkit-animation-name: shineAnimation;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(.12, .89, .98, .47);
}

@keyframes shineAnimation {
  0% {
    background-repeat: no-repeat;
    background-image: -webkit-linear-gradient(top left, rgba(255, 255, 255, 0.0) 0%, rgba(255, 255, 255, 0.0) 45%, rgba(255, 255, 255, 0.5) 48%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.5) 52%, rgba(255, 255, 255, 0.0) 57%, rgba(255, 255, 255, 0.0) 100%);
    background-position: -350px -350px;
    background-size: 300px 300px;
  }

  100% {
    background-repeat: no-repeat;
    background-position: 350px 350px;
  }
}

.side-right .copyright-text p {
  color: #fff !important;
  font-size: 12px;
}

.modal_popup {
  position: fixed;
  width: 50%;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: #625720;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s;
  transform: scale(0);
  z-index: -0;
}

.modal_popup.active {
  transform: scale(1);
  z-index: 111;
}

.wheeler_main {
  /* background: #fff; */
  padding: 20px;
}

.wheeler_box {
  width: 480px;
  height: 480px;
  position: relative;
}

.wheeler_box .spin_button {
  width: 135px;
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  font-family: Gotham;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-51%, -50%);
  z-index: 11;
  border-radius: 50%;
  line-height: 34px;
  background-color: #625720;
  border: none;
  color: #fff;
}

.cuponcode_copy {
  background-color: #ebe8e8;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 20px;
}

.cuponcode_copy button {
  border: none;
  outline: none;
  margin-right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: #0070ba;
  color: #fff;
}

.cuponcode_copy button .fa {
  color: #fff !important;
}

.cuponcode_copy span {
  display: block;
  padding: 0 30px 0 15px;
  font-family: Gotham;
}

.spin_img_box {
  width: 100%;
  position: relative;
  z-index: 11;
}

.wheeler_box .bhdLno {
  position: relative;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  object-fit: contain;
  flex-shrink: 0;
  z-index: 5;
  pointer-events: none;
}

.wheeler_box .bhdLno .sc-dlfnbm::after {
  position: absolute;
  content: '';
  top: 0%;
  left: 0%;
  background-image: url('../img/spin-wheel-v5.png');
  background-size: contain;
  width: 100%;
  height: 100%;
  transform: rotate(125deg);
}

.next_btn_box {
  display: flex;
  align-items: center;
  justify-content: end;
}

.banner_img_slider {
  position: relative;
  width: 100%;
  height: 100%;
}

.banner_img_slider .swiper-slide img {
  width: 100%;
  height: 100%;
}

.copyright-text ul {
  margin-bottom: 0;

}

.copyright-text p {
  margin-bottom: 0;
}

.content_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}


.sec-pad-right-two {
  height: 100%;
}

.sec-pad-right-two .notify-wrap {
  height: 100%;
}

.modal_popup1 {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #0b944494;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  transform: scale(0);
  z-index: -0;
}

.modal_popup1.active {
  transform: scale(1);
  z-index: 111;
}

.winning_page {
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  background-color: #fff;
  border-radius: 10px;
  width: 400px;
}

.winning_page img {
  width: 50%;
  margin-bottom: 40px;
  animation: zoom 0.6s infinite ease-in-out;
}

.right_section_page h3 {
  font-size: 20px;
  color: #000;
  line-height: 24px;
  margin-bottom: 5px;
  padding-bottom: 5px !important;
  padding-top: 0;
  margin-top: 0;
  width: 85%;
  letter-spacing: 1px;
  text-align: center;
  font-family: var(--Helvetica);
}

.right_section_page p {
  text-align: center;
  font-family: var(--Helvetica);
  font-size: 14px;
  color: #000;
  padding-bottom: 0 !important;
}

.winning_page a {
  text-align: center;
  font-family: var(--Helvetica);
  font-size: 16px;
  color: #000;
  padding-bottom: 15px !important;
  text-decoration: underline;
  display: block;
  margin-top: 0;
}

.question_header {
  display: flex;
  justify-content: space-between;
}

.total_question {
  height: 40px;
  border: 1px solid #3785fa;
  background-color: #3785fa;
  /* border-radius: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 10px;
  font-family: var(--BarlowCondensedMedium);
  color: #fff;
}

.highcharts-background {
  fill: transparent !important;
}

.highcharts-title {
  display: none !important;
}

.highcharts-text-outline {
  stroke: none !important;
  fill: #fff !important;
}

.highcharts-label text {
  /* fill: #fff !important; */
  font-weight: normal !important;
  letter-spacing: 1px !important;
}

.content_wrap {
  position: absolute;
  z-index: 1;
}

.footer_copyright {
  display: flex;
  align-items: center;
}

.highcharts-credits {
  display: none;
}

.content_wrap {
  z-index: 11;
}

.form-check {
  display: flex;
  align-items: start;
}

.form-check input {
  margin-right: 5px !important;
}

.content_wrap {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 1;
}

.close_button {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: #000;
  color: #fff !important;
  border: none;
  outline: none;
}

.close_button i {
  color: #fff;
  font-size: 22px;
}

.invalid-feedback {
  color: #fff !important;
}

.form-select {
  --bs-form-select-bg-img: url('../img//down.svg') !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: url('../img/err.svg') !important;
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url('../img/err.svg') !important;
  padding-right: 4.125rem;
  background-position: right .75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
}

.main-section {
  background-color: #0b9444;
  height: 100vh;
  width: 100%;
  position: relative;
  background: url('../img/banner-img.png');
  background-repeat: no-repeat;
  background-position: inherit;
  padding: 0 50px 0px;
  background-size: cover;
  display: flex;
  /* align-items: end; */

}

.main-section::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000067;
  z-index: 1;
}

.logo-start {
  position: relative;
  z-index: 2;
  /* display: none; */
}

.logo-start .logo-box {
  width: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-start .logo-box img {
  width: 85%;
}

.step_1_form {
  display: flex;
  height: 100%;
  align-items: center;
}

.step_1_form h3 {
  font-family: var(--BarlowCondensedRegular);
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 26px;
}

.step_1_form h3 img {
  width: 50px;
  margin-right: 10px;
  animation: zoom 1s ease-in-out infinite;
}

.banner-content {
  position: relative;
  padding: 0 0 0;
  z-index: 2;
  display: flex;
  align-items: center;
}

.banner-content h1 {
  color: #fff;
  font-size: 60px;
  line-height: 63px;
  text-transform: none;
  letter-spacing: 1px;
  font-family: var(--BarlowCondensedMedium);
}

.banner-content h1 span {
  font-size: 40px;
}

.banner-left-section {
  padding-top: 50px;
}

.banner-footer-section {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.banner-footer-section .div_setion {
  display: flex;
  align-items: start;
}

.banner-footer-section .div_setion .content_box {
  display: flex;
  margin-right: 30px;
}

.banner-footer-section .div_setion .content_box img {
  width: 65px;
  height: 55px;
  animation: zoom 1s ease-in-out infinite;
}

::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

@keyframes zoom {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }
}

.banner-footer-section .div_setion .content_box h6 {
  color: #fff;
  font-size: 22px;
  line-height: 28px;
  font-family: GothamBook;
}

.banner-footer-section .div_setion a.lets_start {
  text-decoration: none;
  background-color: #fff;
  color: #0b9444;
  font-family: Gotham;
  border-radius: 50px;
  padding: 15px 20px;
  width: 350px;
  text-align: center;
  font-size: 20px;
  transition: 0.5s;
}

.banner-footer-section .div_setion a.lets_start:hover {
  background-color: #0b9444;
  color: #fff;
}

.heading_content {
  margin-left: 20px;
  position: relative;
}

.Tandc {
  text-decoration: none;
  font-family: GothamBook;
  color: #fff;
  font-size: 14px;
  display: block;
  margin: 10px 0 25px;
}

.report_card {
  width: 100%;
}

.report_card ul {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.report_card ul li {
  width: 100%;
  background-color: #fff;
  border: none;
  padding: 10px;
  text-align: center;
  font-size: 12px;
  font-family: var(--Helvetica);
  /* border-right: 1px solid #000; */
  position: relative;
  color: #fff;
}

.report_card ul li:nth-child(4) {
  background-color: #c73731;
}

.report_card ul li:nth-child(5) {
  background-color: #b9403c;
}

.report_card ul li:nth-child(1) {
  background-color: #86e229;
}

.report_card ul li:nth-child(2) {
  background-color: #ffB801;
}

.report_card ul li:nth-child(3) {
  background-color: #fff900;
  color: #000;
}

.report_card ul li::after {
  position: absolute;
  content: '';
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 15px;
  height: 15px;
  /* background-color: #ffB801; */
  display: none;
}

.report_card ul li:nth-child(1)::after {
  background-color: #86e229;
}

.report_card ul li:nth-child(4)::after {
  background-color: #c73731;
}

.report_card ul li:nth-child(5)::after {
  background-color: #b9403c;
}

.report_card ul li:nth-child(2)::after {
  background-color: #ffB801;
}

.report_card ul li:nth-child(3)::after {
  background-color: #fff900;
  color: #000;
}

.report_card ul li.active {
  /* background-color: #000; */
  /* color: #fff; */
  box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.5);
}

.report_card ul li.active::after {
  display: block;
}

.report_card ul li:last-child {
  border: none;
}


.result_section {
  background: #00800070;
  padding: 0px 20px;
  box-shadow: 0 0 10px rgb(104 77 77 / 60%);
  margin-top: 18px;
  border-radius: 50px;

}

.summary {
  min-width: 100%;
  width: 100%;
  height: 220px;
  background-image: var(--panel-bg-grad);
  border-radius: 0 0 2rem 2rem;
  color: var(--col_lavender);
}

.summary div {
  display: flex;
  align-items: center;
  height: 100%;
}

.summary h1 {
  font-size: 1.35rem;
  font-weight: var(--panel-hdr-weight);
  margin-bottom: 2.2rem;
}

.summary .score-box {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-image: linear-gradient(hsl(332.83deg 6.13% 5.19%), hsl(241deg 72% 46% / 14%));
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.summary .score-box p {
  font-size: 10px;
  margin-top: 0;
  color: #fff;
}

.summary .score-box .score-value {
  font-size: 2rem;
  color: #fff;
  font-weight: 800;
  position: relative;
  display: block;
  font-family: var(--BarlowCondensedMedium);
}

.summary .score-box .score-max {
  font-size: 1rem;
  position: relative;
  display: block;
}

.summary .score-grade {
  font-size: 1.75rem;
  font-weight: 500;
  color: var(--col_white);
  margin-bottom: 1.1rem;
}

.summary .score-rank {
  padding: 0 1rem;
  font-family: GothamBook;
  font-size: 16px;
  text-align: left;
  width: 80%;
}

.wheel {
  transition: transform 6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.loader_section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #625720;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: url('../img/WDD\ web\ banner-1.png');
  background-size: cover;
  background-repeat: no-repeat; */
}

.loding_icon {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loding_icon img {
  width: 50%;
}

.mobile_field_input {
  position: relative;
  display: flex;
}

.mobile_field_input_otp {
  display: flex;
}

.mobile_field_input_otp button {
  height: 38px;
  width: 90px;
  background-color: #ffe000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  border: none;
  margin-left: 4px;
  border-radius: 4px;
  font-size: 12px;
}

.mobile_field_input .mb-3 {

  width: 96%;
}

.mobile_field_input input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  width: 100%;
}

.mobile_field_input .start_number {
  height: 38px;
  width: 45px;
  font-size: 12px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.number-section {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.number-section button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  outline: none;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-family: Gotham;
}

.number-section input {
  width: 60px;
  height: 35px;
  margin: 0 8px;
  background-color: transparent;
  border: 1px solid #fff;
  text-align: center;
  color: #fff;
  font-family: Gotham;
}

.number-section input:focus {
  box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Internet Explorer 10+ */
input[type="number"] {
  -ms-appearance: textfield;
}

/* Remove default styling in older versions of Firefox */
input[type="number"] {
  appearance: none;
}

.result_btn_group {
  display: flex !important;
  align-items: center !important;
  height: auto !important;
}

.summary .score-rank {
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.result_btn_group a,
.result_btn_group button {
  text-decoration: none;
  border: none;
  outline: none;
  padding: 4px 20px;
  color: #000;
  background-color: #fff;
  font-family: var(--BarlowCondensedMedium);
  margin: 20px 10px 0 0 !important;
  font-size: 12px;
  border-radius: 4px;
}

.summary .score-rank div.advixe_box {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  height: auto !important;
}

.summary .score-rank div.advixe_box h4 {
  font-size: 20px;
  font-family: var(--BarlowCondensedMedium);
  margin-bottom: 0;
  color: #fff;
}

.summary .score-rank div.advixe_box p {
  text-align: left;
  color: #fff;
  line-height: 20px;
  font-size: 14px;
  font-family: var(--Helvetica);
}

.pdf_popup {
  position: fixed;
  top: -1000px;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000077;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.pdf_popup.active {
  top: 0%;
}

.pdf_popup .pdf_popup_body {
  width: 80%;
}

.pdf_popup .pdf_popup_body iframe {
  width: 100%;
  height: 100vh;
}

.contact-form-home form {
  padding: 40px;
  background-color: #0000008f;
  border-radius: 10px;
}

/* Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Popup content */
.popup-content {
  background: #fff;
  border-radius: 8px;
  padding: 40px 20px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  /* max-height: 90%; */
  overflow-y: auto;
  width: 500px;
}

/* Header with title and close button */
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.popup-body {
  /* margin-top: 10px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-body img {
  width: 80px;
}

.otp-verification .otp-input {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px 0 20px;
}

.otp-verification .otp-input input {
  width: 50px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #2a652d;
  text-align: center;
}

.popup-body p {
  text-align: center;
  font-size: 14px;
  color: #000;
  margin-top: 10px;
}

.modal_btn_g {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modal_btn_g button {
  border: none;
  outline: none;
  padding: 8px 20px;
}

.modal_btn_g .close_btn {
  background-color: red;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}

.modal_btn_g .okButton {
  background-color: green;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}

.resend-text {
  position: relative;
  display: flex;
  gap: 10px;
  font-size: 12px;
  margin-bottom: 40px;
}

.resend-text .resend-link {
  text-decoration-line: underline;
  cursor: pointer;
}

.social-wrpper-home .fa{
  color: #fff !important;
}
.footer_copyright_home .footer_link li,
.footer_copyright_home .footer_link li a{
  color: #fff;
}

#otpless-login-page-parent{
  display: none !important;
}

.spin-1_btn{
  text-decoration: underline;
  color: #fff;
  padding: 5px 20px;
  background-color: #2a652d;
  font-size: 13px;
}

@media(max-width:1199px) {
  .Quiz_box .answer_list button {
    margin: 5px 0;
    padding: 5px 15px;
  }

  .right_section_page h3 {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 20px;
  }

  .notify-cmpt.notify-form-type-2 .notify-wrap .btn {
    padding: 10px 58px !important;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    margin-right: 20px;
    width: 100px;
  }

  .main-section {
    background-position: 70% 30%;
  }

  .banner-content h1 {
    line-height: 40px;
  }

  .banner-content h1 span {
    font-size: 35px;
  }

  .banner-footer-section .div_setion .content_box h6 {
    font-size: 16px;
    line-height: 20px;
  }

  .Tandc {
    margin: 6px 0 16px;
  }
}

@media(max-width:1024px){
  .modal_popup{
    width: 100%;
    justify-content: center;
  }
  .right_section_page {
    z-index: 1111;
}
}

@media(max-width:991px) {
  .side.side-right {
    min-height: 100%;
  }

  .side.side-right .main-content {
    height: 100%;
    background-color: transparent;
  }

  .padding-top-xs-100 {
    padding-top: 30px;
  }

  .side-right .social-wrpper {
    position: relative;
  }

  .side-right .social-wrpper .margin-top-xs-0 {
    margin-top: 25px !important;
  }

  .Quiz_box .answer_list button {
    width: 100%;
    margin: 8px 0;
    padding: 8px 15px;
  }

  .notify-cmpt.notify-form-type-2 .notify-wrap .btn {
    width: 50%;
    margin-right: 0;
  }

  .button_group {
    justify-content: space-between;
    /* padding-left: 0; */
    gap: 10px;
  }

  .main-section {
    background-position: 50% 30%;
    height: 100%;
    padding: 0 30px 124px;
    background-size: cover;
  }

  .pad-zero.social-wrpper {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .step_1_form h3 {
    text-align: center;
  }

  .summary .score-rank div.advixe_box h4 {
    text-align: center;
  }

  .summary .score-rank div.advixe_box {
    align-items: center;
    justify-content: center;
  }

  .banner-content h1 br {
    display: none;
  }
}

@media(max-width:768px) {
  .popup-content {
    width: 100%;
  }
}

@media(max-width:576px) {
  .side-right .sec-pad-right {
    padding-right: 10px;
    padding-left: 10px;
  }

  .right_section_page {
    padding-top: 0;
  }

  .side-right .social-wrpper {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .copyright-text ul {
    margin-bottom: 0;
    justify-content: center;
    display: flex;
    width: 100%;
  }

  .footer_link {
    flex-direction: column;
    padding-top: 20px;
    width: 100%;
  }

  .footer_link li:nth-child(3) {
    display: none;
  }

  .footer_copyright {
    width: 100%;
  }

  .side-right .social-wrpper .margin-top-xs-0 {
    margin-top: 50px !important;
  }

  .side.side-left {
    min-height: 100%;
  }

  .side-left .content-wrap {
    padding-left: 0;
  }

  .total_question {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }

  .Quiz_box .answer_list {
    padding-left: 0;
  }

  .button_group {
    padding-left: 0;
  }

  .wheeler_box {
    width: 270px;
    height: 270px;
  }

  .modal_popup {
    height: 100%;
  }

  .notify-cmpt.notify-form-type-2 .notify-wrap .btn {
    border-left: 0 !important;
  }

  .footer_link li {
    padding-top: 8px;
  }

  .social-icons {
    margin-right: 0;
  }

  .complete_section {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding: 0 0;
  }

  .summary {
    height: auto;
    padding: 30px 0;
  }

  .summary div {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  .summary .score-rank {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    padding: 0;
  }

  .result_section {
    background: #00800070;
    padding: 0px 10px;
  }

  .report_chart {
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .Quiz_box .question {
    font-size: 16px;
    margin-bottom: 14px;
    text-align: left;
  }

  .summary .score-rank div.advixe_box p {
    text-align: center;
  }

  .notify-cmpt.notify-form-type-2 .notify-wrap .btn {
    padding: 8px 58px !important;
  }

  .banner-footer-section .div_setion {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
  }

  .main-section {
    background-position: right;
    padding: 0 20px 20px;
    /* padding: 0 20px; */
  }

  .banner-content h1 br {
    display: none;
  }

  .banner-content h1 {
    font-size: 30px;
    line-height: 35px;
  }

  .banner-content h1 span {
    font-size: 20px;
  }

  .logo-start .logo-box {
    width: 120px;
    height: 125px;
  }

  .banner-footer-section .div_setion .content_box h6 {
    font-size: 18px;
    line-height: 24px;
  }

  .banner-footer-section .div_setion a.lets_start {
    padding: 12px 20px;
    width: 100%;
    font-size: 20px;
  }

  .banner-footer-section {
    display: flex;
    align-items: center;
    padding-top: 20px;
    margin-bottom: 0;
  }

  .main-section {
    /* height: 100vh; */
  }



  .banner-content {
    position: relative;
    padding: 0 0 20px;
  }

  .banner-footer-section .div_setion .content_box {
    display: flex;
    margin-right: 0;
    margin-bottom: 20px;
    margin-top: 25px;
  }

  .report_card ul li {
    padding: 5px;
    font-size: 10px;
    display: flex;
    align-items: center;
    height: 55px;
  }

  .summary .score-box p {
    font-size: 10px;
    margin-top: 5px;
  }

  .wheeler_main {
    /* background: #fff; */
    padding: 0;
  }

  .wheeler_box {
    width: 350px;
    height: 350px;
  }

  .banner-left-section {
    padding-top: 30px;
  }

  .pdf_popup .pdf_popup_body .close_button {
    position: absolute;
    top: 15px;
    right: 14px;
  }

  .result_btn_group a,
  .result_btn_group button {
    margin: 24px 10px 10px !important;
  }

  .pad-zero.social-wrpper {
    margin-top: 30px;
  }

  .wheeler_box .spin_button {
    width: 80px;
    height: 80px;
    font-size: 24px;
    line-height: 24px;
  }

  .winning_page {
    width: 100%;
  }

  .contact-form-home form {
    padding: 40px 20px;
  }

  .step_1_form h3 {

    font-size: 16px;
    line-height: 20px;
  }

  .otp-verification .otp-input input {
    width: 35px;
    height: 35px;
  }

  .resend-text {
    position: relative;
    display: flex;
    gap: 10px;
    font-size: 12px;
    margin-bottom: 26px;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 12px;
  }
  .mobile_field_input_otp button{
    width: 70px;
  }
}

@media(max-width:375px) {
  .report_card ul li {
    padding: 1px;
    font-size: 9px;
  }

  .wheeler_box {
    width: 320px;
    height: 320px;
  }

  .banner-content h1 {
    font-size: 25px;
    line-height: 30px;
  }

  .banner-content h1 span {
    font-size: 17px;
  }
}